import { axiosWrapper } from "../utils/helper";
import { api } from "./api";


export class AuthService {
  private static _getToken() {
    let branchId = process.env.REACT_APP_BRANCH_ID;
    let clientKey = process.env.REACT_APP_CLIENT_KEY;
    let encodedData = window.btoa(branchId + ":" + clientKey);
    let authorizationHeaderString = "Token " + encodedData;
    return authorizationHeaderString;
  }

  static async refreshToken() {
    return axiosWrapper<{ accessToken: string }>(
      api.post("/auth/refresh-token", {}, {
        withCredentials: true,
      } as any)
    );
  }

  static async loginByOTP(otp: string) {
    return axiosWrapper<{ accessToken: string }>(
      api.get(`/auth/sms/${otp}`)
    );
  }

  static async getAnonymousToken() {
    return axiosWrapper<{ authorization_token: string }>(
      api.get("/auth/mobile/generate-auth-token")
    );
  }

  static async loginByPhone(fullNumber: string) {
    const data = {
      phone: fullNumber,
      channel: "sms",
    };
    const token = AuthService._getToken();

    return axiosWrapper<{
      success: boolean;
    }>(
      api.post("/auth/verifications", data, {
        headers: {
          ignoreInterceptors: true,
          Authorization: token,
        },
      })
    );
  }

  static async verifyPhoneOTP(phone: string, code: string) {
    const data = {
      phone,
      code,
    };
    const token = AuthService._getToken();

    return axiosWrapper<{
      success: boolean;
      accessToken: string;
    }>(
      api.post("/auth/verification-check", data, {
        headers: {
          ignoreInterceptors: true,
          Authorization: token,
        },
      })
    );
  }

  static async optOut() {
    return axiosWrapper<string>(
      api.post(
        "/users/opt-out",
        {},
      )
    );
  }
}
