import jwtDecode from 'jwt-decode';
import type { Token } from '../models/token.model';
import type { User } from '../models/user.model';
import { ApiResponse } from '../services/api';

export type OkAppError = {
    error: unknown;
    errorMessage: string;
    statusCode: number;
    _isAppError: boolean;
};

export const isMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
        return window.navigator.userAgent.match(toMatchItem);
    });
};

export const isIOSMobile = () => {
    const toMatch = [/iPhone/i, /iPad/i, /iPod/i];

    return toMatch.some((toMatchItem) => {
        return window.navigator.userAgent.match(toMatchItem);
    });
};

export async function axiosWrapper<T>(
    promise: Promise<ApiResponse<T>>,
): Promise<T> {
    try {
        const res = await promise;
        return res.data!;
    } catch (error: any) {
        if (error._isAppError) return Promise.reject(error);

        return Promise.reject({
            error,
            errorMessage: error.response?.data?.message || error.message,
            statusCode:
                error.response?.data?.statusCode ||
                error.response?.status ||
                500,
            _isAppError: true,
        } as OkAppError);
    }
}

export function callAll(...callbacks: Function[]) {
    return (...args: any[]) => {
        callbacks.forEach((cb) => cb(...args));
    };
}

export function getUserDetailsFormToken(token: string): User {
    const tokenPayload = jwtDecode<Token>(token);
    return tokenPayload.user;
}

export async function copyToClipboard(text: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
}
