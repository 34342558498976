import React from "react";
import { ChakraProvider, extendTheme, ButtonProps } from "@chakra-ui/react";
import { Global, css } from "@emotion/react";

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

// 2. Extend the theme to include custom colors, fonts, etc
export const colors = {
  brand: {
    50: "#f2f7f7",
    100: "#E8F7F7", //'#e6eff0',
    200: "#bfd7d9",
    300: "#99bec2",
    400: "#4d8e95",
    500: "#005D67",
    600: "#00545d",
    700: "#00464d",
    800: "#00383e",
    900: "#002e32",
  },
  primary: {
    50: "#f2f7f7",
    100: "#E8F7F7", //'#e6eff0',
    200: "#bfd7d9",
    300: "#99bec2",
    400: "#4d8e95",
    500: "#005D67",
    600: "#00545d",
    700: "#00464d",
    800: "#00383e",
    900: "#002e32",
  },
  secondary: {
    50: "#f9fffc", // "#f9fffc14"
    100: "#f3fff9", // "#f3fff929"
    200: "#e1fff1",
    300: "#ceffe9",
    400: "#aaffd8",
    500: "#85ffc7",
    600: "#78e6b3",
    700: "#64bf95",
    800: "#509977",
    900: "#417d62",
  },
};

const theme = extendTheme({
  config: {
    // If change make sure to update in <ChakraPhoneNumberInputProps /> styles.scss file
    cssVarPrefix: 'okHi',
  },
  fonts: {
    heading: "Roboto, sans-serif",
    body: "Roboto, sans-serif",
  },
  colors,
  components: {
    Button: {
      baseStyle: () => ({
        fontWeight: "normal",
      }),
      variants: {
        solid: (props: ButtonProps) => ({
          color:
            props.colorScheme === "primary"
              ? "secondary.500"
              : props.colorScheme === "secondary"
              ? "primary.500"
              : undefined,
        }),
      },
    },
  },
});

type Props = {};

const ThemeProvider: React.FC<Props> = ({ children }) => {
  return (
    <ChakraProvider theme={theme} resetCSS={true}>
      <Global styles={GlobalStyles} />
      {children}
    </ChakraProvider>
  );
};

export default ThemeProvider;
