import React, { ReactElement } from "react";
import { AppPages } from "../services/analytics/okTracker";
import AddressSkeleton from "./Address/AddressSkeleton";
import { AppNav } from "./AppNav/AppNav";

function LazyComponentLoader(): ReactElement {
  return (
    <div className="fixed top-0 mx-auto w-screen h-full flex flex-col font-roboto desktop-layout">
      <AppNav showBack={false} showMenu={false} trackerView={AppPages.root} />
      <div className="relative overflow-y-hidden bg-gray-50 flex-grow">
        <div className="landing-container h-full flex flex-col relative overflow-y-auto">
          <div className="flex-1">
            <div className="address-wrapper px-6 pt-6 pb-2">
              <div className="transition-all duration-500">
                <AddressSkeleton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LazyComponentLoader;
