let accessToken = '';
let anonymousAccessToken = '';

export const setAccessToken = (token: string) => {
  accessToken = token;
};

export const getAccessToken = (): string => accessToken;


export const setAnonymousAccessToken = (token: string) => {
  anonymousAccessToken = token;
};

export const getAnonymousAccessToken = (): string => anonymousAccessToken;
