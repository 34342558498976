import React, { useState, memo, useCallback } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AppNav } from "./AppNav/AppNav";
import cn from "classnames";
import { Props } from "../utils/types/types";
// import PWAPrompt from "./PWAPrompt";
import GlobalLoadingIndicator from "./GlobalLoadingIndicator";
import OfflineIndicator from "./OfflineIndicator";

// Analytics
import { AppPages } from "../services/analytics/okTracker";
import { trackBackPress } from "../services/analytics/trackerEvents";

export interface LayoutProps {
  showBack?: boolean;
  hideHamburger?: boolean;
  onBackPress?: VoidFunction;
  stickyHeader?: React.ReactNode;
  trackerView: AppPages;
  allowedPWAPopup?: boolean;
  showDataLoader?: boolean;
}
let DEFAULT_MENU_TAG = "main" as React.ElementType;

const LayoutComponent = <
  TTag extends React.ElementType = typeof DEFAULT_MENU_TAG
>({
  children,
  showBack,
  onBackPress,
  hideHamburger,
  stickyHeader,
  trackerView,
  allowedPWAPopup,
  showDataLoader,
  as: AsComponent,
  className,
  ...props
}: Props<TTag, never, "as"> & LayoutProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const { url } = useRouteMatch();
  const history = useHistory();

  const hideMenu = () => {
    if (showMenu) setShowMenu(false);
  };

  const handleBackPress = useCallback(() => {
    trackBackPress(trackerView);

    if(onBackPress) return onBackPress();

    if (history.length > 2) {
      history.goBack()
      return;
    } else {
      history.push("/");
    }
  }, [trackerView, onBackPress, history]);

  const Component = AsComponent || DEFAULT_MENU_TAG;
  return (
    <Component
      onClick={hideMenu}
      className={cn(
        "fixed top-0 mx-auto w-screen h-full flex flex-col font-roboto overflow-y-hidden desktop-layout",
        className
      )}
      {...props}
    >
      <OfflineIndicator position="bottom" />
      {showDataLoader && <GlobalLoadingIndicator />}
      {/* <PWAPrompt show={allowedPWAPopup} /> */}
      <AppNav
        onBackPress={handleBackPress}
        showBack={showBack}
        showMenu={!hideHamburger}
        trackerView={trackerView}
        currentUrl={url}
      />
      {stickyHeader}
      <div className="relative overflow-y-hidden bg-gray-50 flex-grow">
        {children}
      </div>
    </Component>
  );
};


export const Layout = memo(LayoutComponent) as typeof LayoutComponent;
