import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as analytics from "../services/analytics/okTracker";

let previousPathname = '';

const RouteChangeTracker = () => {
  const history = useHistory();
  useEffect(() => {
    previousPathname = history.location.pathname;
    analytics.page(previousPathname);

    const unregister = history?.listen((location, action) => {
      const currentPathname = location.pathname;
      if (previousPathname !== currentPathname) {
        analytics.page(currentPathname);
        previousPathname = currentPathname;
      }
    });

      return () => {
        unregister();
        previousPathname = '';
      }
  }, [history]);

  return null;
};

export default RouteChangeTracker;
