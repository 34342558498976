import { PropsWithChildren, Suspense, lazy } from "react";
import {
  QueryClient,
  QueryClientProvider,
  QueryClientProviderProps,
} from "react-query";

const LazyReactQueryDevtools = lazy(() =>
  import("react-query/devtools").then(module =>
    Promise.resolve({ default: module.ReactQueryDevtools })
  )
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      // Uncomment it to disable "stale while revalidate" strategy for API
      // cacheTime: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: false,
    },
  },
});

export default function ReactQueryProvider({
  children,
  client = queryClient,
  ...rest
}: PropsWithChildren<Partial<QueryClientProviderProps>>) {
  return (
    <QueryClientProvider client={client} {...rest}>
      {children}
      {process.env.REACT_APP_REACT_QUERY_DEVTOOL && (
        <Suspense fallback={null}>
          <LazyReactQueryDevtools initialIsOpen={false} />
        </Suspense>
      )}
    </QueryClientProvider>
  );
}
