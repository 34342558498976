import { Progress } from "@chakra-ui/react";
import { useIsFetching } from "react-query";

export default function GlobalLoadingIndicator() {
  const isFetching = useIsFetching();
  return isFetching ? (
    <Progress
      isIndeterminate
      colorScheme="primary"
      size="xs"
      position="absolute"
      width="100%"
      bgColor="transparent"
    />
  ) : null;
}
