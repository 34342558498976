import { axiosWrapper } from "../utils/helper";
import { api } from "./api";

export const AppServices = {
  async getSupportWANumber() {
    const { value } = await axiosWrapper<{ value: string }>(
      api.get("/support?channel=whatsapp")
    );
    return value;
  },
};
