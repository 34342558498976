import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Skeleton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo-OkHi-primary.svg";
import MenuIcon from "../../assets/menu-primary.svg";
import {
  WHATSAPP_INITIAL_MESSAGE,
  // WHATSAPP_SUPPORT_NUMBER,
} from "../../Constants/support";
import { AppPages } from "../../services/analytics/okTracker";
import {
  trackHamburgerClick,
  trackNavMenuItemClick,
} from "../../services/analytics/trackerEvents";
import { useAuthStore } from "../../zustand/useAuthStore";
import { IconButton } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useQuery } from "react-query";
import { AppServices } from "../../services/app";

type Props = {
  showBack?: boolean;
  onBackPress?: VoidFunction;
  showMenu?: boolean;
  trackerView: AppPages;
  currentUrl?: string;
};

export const AppNav = ({
  showBack,
  onBackPress,
  showMenu,
  trackerView,
  currentUrl,
}: Props) => {
  const { user } = useAuthStore();

  const goBack = () => {
    onBackPress?.();
  };

  const onOptOutHandler = () => {
    trackNavMenuItemClick(trackerView, "Opt-out", 2);
  };

  const {
    isLoading: isLoadingWaPhoneNumber,
    data: waPhoneNumber,
    isError: errorLoadingWaPhoneNumber,
  } = useQuery("supportWANumber", AppServices.getSupportWANumber, {
    retry: true,
  });

  return (
    <nav className="flex items-center justify-between w-full p-2 bg-primary-100 h-14">
      <div className="flex items-center h-full">
        {showBack && (
          <IconButton
            variant="ghost"
            fontSize="20px"
            icon={<ArrowBackIcon />}
            aria-label="Go back"
            _hover={{ backgroundColor: "none" }}
            colorScheme="primary"
            onClick={goBack}
          />
        )}
        <Link
          to={"/"}
          className={`${!showBack && "ml-2"} flex items-center h-full`}>
          <img src={Logo} alt="logo" />
        </Link>
      </div>

      {showMenu && (
        <Menu placement="bottom-end">
          <MenuButton p={2} onClick={() => trackHamburgerClick(trackerView)}>
            <img src={MenuIcon} alt="menu" />
          </MenuButton>
          <MenuList className="relative w-2 -top-10">
            <MenuItem
              as={Link}
              to="/about"
              isDisabled={currentUrl === "/about"}
              onClick={() => trackNavMenuItemClick(trackerView, "About", 0)}>
              About
            </MenuItem>

            {!errorLoadingWaPhoneNumber ? (
              <Skeleton isLoaded={!isLoadingWaPhoneNumber}>
                <MenuItem
                  as="a"
                  href={`https://wa.me/${waPhoneNumber}?text=${encodeURI(
                    WHATSAPP_INITIAL_MESSAGE
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    trackNavMenuItemClick(trackerView, "Support", 1)
                  }>
                  Support
                </MenuItem>
              </Skeleton>
            ) : null}

            {!!user && (
              <MenuItem as={Link} to="/opt-out" onClick={onOptOutHandler}>
                Opt-out
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}
    </nav>
  );
};
