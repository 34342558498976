import React, { lazy, Suspense, useEffect } from "react";
import { useQueryClient } from "react-query";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import AppError from "../components/AppError";
import { Layout } from "../components/Layout";
import LazyComponentLoader from "../components/LazyComponentLoader";
import RouteChangeTracker from "../components/RouterChangeTracker";
import ProtectedRoute from "../Context/ProtectedRoute";
import { AppPages, TrackingLocations } from "../services/analytics/okTracker";
import { trackButtonTapped } from "../services/analytics/trackerEvents";
import { AppServices } from "../services/app";

const Login = lazy(() => import("../pages/Login"));
const Landing = lazy(() => import("../pages/Landing"));
const About = lazy(() => import("./about/About"));
const HowItWorks = lazy(() => import("./about/HowItWorks"));
const AddressViewer = lazy(() => import("./AddressViewer"));
const Onboarding = lazy(() => import("./Onboarding"));
const CreateUserPage = lazy(() => import("./CreateUserPage/CreateUserPage"));
const CreateAddressPage = lazy(() => import("./CreateAddressPage"));
const OptOut = lazy(() => import("./OptOut"));

const Routes = () => {
  const queryClient = useQueryClient();
  useEffect(() => {
    const prefetchSupportWANumber = async () => {
      await queryClient.prefetchQuery(
        "supportWANumber",
        AppServices.getSupportWANumber,
        {
          retry: true,
        }
      );
    };
    prefetchSupportWANumber();
  }, [queryClient]);
  return (
    <Router>
      <Suspense fallback={<LazyComponentLoader />}>
        <RouteChangeTracker />
        <Switch>
          <ProtectedRoute
            key="/login"
            exact
            path="/login"
            onUnauthorizedComponent={() => <CreateUserPage />}>
            {/* If already logged in then redirect to Base */}
            <Redirect to="/" />
          </ProtectedRoute>

          <Route exact path="/login/:id">
            <Login />
          </Route>

          <ProtectedRoute exact path="/create-address" key="/create-address">
            <CreateAddressPage />
          </ProtectedRoute>
          <ProtectedRoute
            key="/"
            path="/"
            exact
            loadingComponent={<LazyComponentLoader />}
            onUnauthorizedComponent={({ error, history }) => (
              <Onboarding
                CTOLabel="Continue"
                onCTOClick={() => {
                  history.replace("/login");
                  trackButtonTapped({
                    view: AppPages.landing,
                    label: "Continue",
                    location: TrackingLocations.mainContent,
                  });
                }}
              />
            )}>
            <Landing />
          </ProtectedRoute>

          <Route exact path="/about">
            <About />
          </Route>

          <Route exact path="/about/how-it-works">
            <HowItWorks />
          </Route>

          <ProtectedRoute
            key="/opt-out"
            exact
            path="/opt-out"
            onUnauthorizedComponent={() => <CreateUserPage />}>
            <OptOut />
          </ProtectedRoute>

          {/* Always keep this route at below all valid routes */}
          <ProtectedRoute
            path="/:addressId"
            key="/:addressId"
            exact
            allowUnauthorized
            loadingComponent={<LazyComponentLoader />}
            onUnauthorizedComponent={({ error }) => (
              <Layout trackerView={AppPages.root}>
                <AppError
                  code={`${error.statusCode}`}
                  message={error.errorMessage}
                />
              </Layout>
            )}>
            <AddressViewer />
          </ProtectedRoute>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
