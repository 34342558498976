import { useEffect, useState } from "react";
/**
 * 
 * @description Use `networkState` instead, This is used by networkState internally
 */
const useNetwork = () => {
  const [state, setState] = useState<
    { since?: string; online: boolean } & ReturnType<
      typeof getNetworkConnectionInfo
    >
  >(() => {
    return {
      since: undefined,
      online: window.navigator.onLine,
      ...getNetworkConnectionInfo(),
    };
  });
  useEffect(() => {
    const handleOnline = () => {
      setState(prevState => ({
        ...prevState,
        online: true,
        since: new Date().toString(),
      }));
    };
    const handleOffline = () => {
      setState(prevState => ({
        ...prevState,
        online: false,
        since: new Date().toString(),
      }));
    };
    const handleConnectionChange = () => {
      setState(prevState => ({
        ...prevState,
        ...getNetworkConnectionInfo(),
      }));
    };
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    const connection = getNetworkConnection();
    connection?.addEventListener("change", handleConnectionChange);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      connection?.removeEventListener("change", handleConnectionChange);
    };
  }, []);

  return state;
};

function getNetworkConnection(): NetworkInformation {
  return (
    window?.navigator.connection ||
    (window?.navigator as any).mozConnection ||
    (window?.navigator as any).webkitConnection ||
    null
  );
}
function getNetworkConnectionInfo() {
  const connection: any = getNetworkConnection();
  if (!connection) {
    return {};
  }
  return {
    rtt: connection.rtt,
    type: connection.type,
    saveData: connection.saveData,
    downLink: connection.downLink,
    downLinkMax: connection.downLinkMax,
    effectiveType: connection.effectiveType,
  };
}

export default useNetwork;
