import axios from "axios";
import { getAccessToken, getAnonymousAccessToken } from "../utils/token";
import { networkState } from "../zustand/networkState";
import { useAuthStore } from "../zustand/useAuthStore";
import { AuthService } from "./auth";

export interface ApiResponse<T> {
    data?: T;
    statusCode?: number;
    error?: string;
}

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 120000, // 2 mint
    timeoutErrorMessage: "Request timeout",
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
});

api.interceptors.request.use(
    (config) => {
        if (config.headers["ignoreInterceptors"]) {
            delete config.headers.ignoreInterceptors;
            return config;
        }

        if (
            config.url?.includes("/auth/mobile/generate-auth-token") ||
            config.url?.includes("/support") ||
            config.url?.includes("/refresh") ||
            config.url?.includes("/auth/sms")
        ) {
            if (process.env.REACT_APP_OKHI_KEY) {
                config.headers.Authorization = `Token ${process.env.REACT_APP_OKHI_KEY}`;
            }
            return config;
        } else {
            const token = getAccessToken() || getAnonymousAccessToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);
const NO_RETRY_FOR_ROUTES = [
    "/auth/sms/",
    "/auth/refresh-token",
    "/auth/verifications",
    "/auth/verification-check",
    "/users/opt-out",
];

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const config = error.config;
        const shouldRetry = !NO_RETRY_FOR_ROUTES.some((r) =>
            config.url.includes(r)
        );
        if (error.response && shouldRetry) {
            if (
                error.response.status === 401 &&
                !config._retry &&
                networkState.getState().online
            ) {
                config._retry = true;
                try {
                    const res = await AuthService.refreshToken();
                    useAuthStore.getState().setUserByToken(res.accessToken);
                    return api(config);
                } catch (err) {
                    useAuthStore.getState().removeUserData();
                    return Promise.reject(err);
                }
            }
        }
        return Promise.reject(error);
    }
);

export { api };

// https://dev-api.okhi.io/v5/users/login/sms/DyuP47sRRt  6B2EvF3xFB
