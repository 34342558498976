import jwtDecode from "jwt-decode";
import create from "zustand";
import { combine } from "zustand/middleware";
import { Token } from "../models/token.model";
import { User } from "../models/user.model";
import { setAccessToken, setAnonymousAccessToken } from "../utils/token";
import * as analytics from "../services/analytics/okTracker";
import { storageService } from "../services/storage";
import { AuthService } from "../services/auth";
import * as sentry from "../sentry/sentryConfig";
import { OkAppError } from "../utils/helper";
import { removeAllUserCache } from "../utils/cacheStorage";

export interface IAuthState {
  user: User | null;
  app: Token["app"] | null;
  isAuthenticating: boolean;
  login: (id: string) => Promise<{ accessToken: string }>;
  setUserByToken: (token: string) => void;
  refreshToken: () => Promise<{ accessToken: string }>;
  removeUserData: VoidFunction;
}

const initialState: IAuthState = {
  user: null,
  app: null,
  isAuthenticating: false,
  login: (otp: string) =>
    Promise.reject(new Error("Not implemented, Check 'useAuthState'")),
  setUserByToken: () => {},
  refreshToken: async () => Promise.reject(new Error("Not implemented, Check 'useAuthState'")),
  removeUserData: () => {},
};

export const useAuthStore = create(
  combine(initialState, (set, get) => ({
    setUserByToken: (token: string) => {
      setAccessToken(token);
      const tokenPayload: Token = jwtDecode(token);
      analytics.setUserTracking(token);
      sentry.setUser(tokenPayload.user);
      set({
        user: tokenPayload.user,
        app: tokenPayload.app,
        isAuthenticating: false,
      });
    },

    updateUser: (user: User) => {
      set({
        user: {
          ...get().user,
          ...user,
        },
      });
    },

    login: async (id: string) => {
      set({ isAuthenticating: true });
      try {
        const data = await AuthService.loginByOTP(id);
        const token = data.accessToken;
        get().setUserByToken(token);

        storageService.saveId(id); // Its required to maintain logged in state in offline mode
        return data;
      } catch (error) {
        set({ user: null, app: null, isAuthenticating: false });
        return Promise.reject(error);
      }
    },

    refreshToken: async () => {
      try {
        set({ isAuthenticating: true });
        const data = await AuthService.refreshToken();
        get().setUserByToken(data.accessToken);
        return data;
      } catch (error) {
        const err = error as OkAppError;
        if (err.errorMessage !== "Network Error") {
          get().removeUserData();
        } else {
          set({ user: null, app: null, isAuthenticating: false });
        }
        return Promise.reject(error);
      }
    },

    removeUserData: async () => {
      setAccessToken("");
      setAnonymousAccessToken("");
      storageService.removeId();
      set({ user: null, app: null, isAuthenticating: false });
      sentry.removeUser();
      await removeAllUserCache();
    },

    optOut: async () => {
      const { removeUserData } = get();
      await AuthService.optOut();
      removeUserData();
    },
  }))
);
