import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { User } from "../models/user.model";

export function init(options?: Sentry.BrowserOptions) {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    release: "OkApp@" + process.env.REACT_APP_VERSION,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || '0'), // set it between 0 to 1;
    ...options
  });
}

export function setUser(user: User) {
  Sentry.setUser(user);
}

export function removeUser() {
  Sentry.configureScope(scope => scope.setUser(null));
}