import { Token } from '../../models/token.model';

type Environment = 'development' | 'production' | 'sandbox';
class ProductSuiteName {
    static OkCollect = 'OkCollect';
    static OkVerify = 'OkVerify';
}
class PlatformName {
    static web = 'web';
    static mobile = 'mobile';
    static android = 'android';
    static ios = 'ios';
    static hybrid = 'hybrid';
    static desktop = 'desktop';
}
class ComponentName {
    static manager = 'manager';
    static card = 'card';
    static okDash = 'okDash';
    static okApp = 'okApp';
}
interface Context {
    productSuite: {
        /* Refers to the "umbrella" to which the product belongs to eg.
        OkCollect, OkVerify*/
        name: ProductSuiteName;
    };
    platform: {
        /* Refers to the current runtime environment */
        name: PlatformName;
    };
    developer: {
        /* This basically tells us who built which container. It lets us answer how are our own off-the-shelf products doing aganist something built in-house by one of our customers */
        name: 'okhi' | 'external';
    };
    container?: {
        /* Refers to the "wrapper" that aggregates one or more components under the productSuite. And interacts with these components, strictly, using our provided libraries eg. woocommerce, okcollect, okapp etc */
        name: string;
        /* The current version of the "wrapper" prodivded by customers at sign up or possibly through configuration of the library eg. 2.3.1 */
        version: string;
    };
    library?: {
        /* The name of the library that's used to provide access to one or more components under the product suite umbrella. For it to be considred a library it has to satisfy atlease one of the following criterias:
        1. Provide access to a component in the product suite.
        2. Interact with our APIs on behalf of the current container" */
        name: string;
        version: string;
    };
    component?: {
        /* This refers to the current, for a lack of a better word, "element" that an end user is interacting with (keyword === current) */
        name: ComponentName;
        version: string;
    };
    org?: {
        /* Retrieved from Auth token */
        id?: string;
        name?: string,
    };
    app?: {
        /* Retrieved from Auth token */
        id?: string;
        name?: string;
    };
    branch?: {
        /* Retrieved from Auth token */
        id?: string;
        name?: string;
    };
}
interface BasePayload {
    environment: Environment;
    context: Context;
    userId?:string,
    firstName?: string;
    lastName?: string;
    phone?: string;
}

interface AppContext {
    name: ComponentName
    version: string
    build: number
    layer: string
    namespace: string
}

let basePayload: BasePayload
export const appContext: AppContext = {
    name: process.env.REACT_APP_NAME as ComponentName,
    version: process.env.REACT_APP_VERSION as string,
    build: parseInt(process.env.REACT_APP_BUILD_NUMBER as string, 10),
    layer: 'client',
    namespace: `com.${
        (process.env.REACT_APP_ENV as Environment) || 'development'
    }.${process.env.REACT_APP_NAME as ComponentName}.okhi`,
}
export function setBasePayload(data?: Token) {
    basePayload = {
        environment: (process.env.REACT_APP_ENV as Environment) || 'development', // TODO double chck this is firing
        context: {
            productSuite: {
                name: 'OkCollect',
            },
            platform: {
                name: 'web',
            },

            component: {
                name: process.env.REACT_APP_NAME as ComponentName,
                version: process.env.REACT_APP_VERSION as string,
            },
            developer: {
                name: 'okhi',
            },
        },
    };
    const {user,app, branch} = data || {}
    const {org} = app || {}
    if (org) {
        basePayload.context.org = {
            id: org.id,
            name: org.name,
        };
    }
    if (app) {
        basePayload.context.app = {
            id: app.id,
            name: app.name,
        };
    }
    if (branch) {
        basePayload.context.branch = {
            id: branch.id,
            name: branch.name,
        };
    }
    if(user){
        basePayload.userId = user.id;
        basePayload.firstName = user.first_name;
        basePayload.lastName = user.last_name;
        basePayload.phone = user.phone;
    }
}
export function getBasePayload() {
    return basePayload;
}