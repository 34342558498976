export enum CacheNames {
  apiCache = "apiCache",
  googleMapCache = "googleMapCache",
  images = "images",
}

export async function getDataFromCacheStorage<T>(
  cacheName: CacheNames,
  url: string
): Promise<T | null> {
  const cache = await caches.open(cacheName);
  const cacheResponse = await cache.match(url);
  if (cacheResponse) return cacheResponse?.json();
  return null;
}

export async function getCacheKeys(cacheName: CacheNames) {
  const cache = await caches.open(cacheName);
  return cache.keys();
}

export async function deleteCacheByName(cacheName: CacheNames) {
  return caches.delete(cacheName);
}

export async function removeAllUserCache() {
  return Promise.all([
    deleteCacheByName(CacheNames.apiCache),
    deleteCacheByName(CacheNames.images),
  ]);
}
