import jwt_decode from 'jwt-decode';
import { Token } from '../../models/token.model';
import { User } from '../../models/user.model';
import { appContext, getBasePayload, setBasePayload } from './basePayload';

export enum AppPages {
  root = '/',
  landing = 'landing',
  addressBook = 'addressBook',
  about = 'about',
  addressViewer = 'addressViewer',
  howItWorks = 'howItWorks',
  createUser = 'createUser',
  createAddress = 'createAddress',
  phoneNumber = 'phoneNumber',
  verificationCode = 'verificationCode',
  outOut = 'outOut',
}

export enum TrackingEventNames {
  BUTTON_TAPPED = 'Button Tapped',
  ADDRESS_CARD_TAPPED = 'Address Card Tapped',
  ADDRESS_LOADED = 'Addresses Loaded',
  PHONE_NUMBER_SUBMITTED = 'Phone number submitted',
  VERIFICATION_CODE_SUBMITTED = 'Verification Code Submitted',
  ACCORDION_TOGGLED = 'Accordion Toggled',
  PHOTO_EXPANDED = 'Photo Expanded',
}

export enum TrackingObjects {
  app = 'app',
  button = 'button',
  user = 'user',
  userAddress = 'userAddress',
  phoneNumber = 'phoneNumber',
  verificationCode = 'verificationCode',
  accordion = 'accordion',
  photo = 'photo',
}

export enum TrackingLocations {
  mainContent = 'Main content',
  headerBar = 'Header bar',
  footerCard = 'Footer card',
  topBar = 'Top bar',
  menu = 'Menu',
  modal = 'modal',
}

export enum TrackingActions {
  tapClick = 'tapclick',
  loaded = 'loaded',
  toggle = 'toggle',
}

export interface EventProperties {
  action: string;
  onObject: string;
  view: AppPages;
  label?: string;
}
export const load = () => {
  const writeKey = process.env.REACT_APP_SEGMENT_WRITE_KEY || 'myTestKey';
  window.analytics.load(writeKey);
};
export const page = (...args: any[]) => {
  let category = 'other';
  let name = 'other';
  const path = args[0];
  // TODO rm this hack
  setBasePayload();

  let options: SegmentAnalytics.SegmentOpts = { ...getBasePayload() };

  if (path?.includes('login')) {
    category = 'auth';
    name = 'Login';
  } else if (path.includes('about')) {
    category = 'info';
    name = 'about';
  } else if (path.includes('opt-out')) {
    category = 'optOut';
    name = 'optOut';
  } else if (path.includes('create-address')) {
    category = 'address';
    name = 'createAddress';
  }

  window.analytics.page(category, name, options);
};
export const track = (
  name: string,
  properties: EventProperties & Record<string, string | number | boolean>
) => {
  const sendToAmplitude = (eventName: string) => {
    const noisyEvents = ['GeoPoint Found'];

    return !noisyEvents.includes(eventName);
  };

  const sendToMixpanel = (eventName: string) => {
    const eventsToSendToMixpanel = [
      'Button Tapped',
      'App Loaded',
      'Address Created',
      'Address Collected',
      'Address Selected',
      'Address Creation Failed',
      'Addresses Loaded'
    ];

    return eventsToSendToMixpanel.includes(eventName);
  };

  window.analytics.track(
    name,
    {
      ...getBasePayload(),
      ...properties,
    },
    {
      context: {
        app: {
          ...appContext,
        },
      },
      integrations: {
        All: true,
        Amplitude: sendToAmplitude(name),
        Mixpanel: sendToMixpanel(name),
      },
    }
  );
};
export const identify = (user: User) => {
  window.analytics.identify(user.id, {
    firstName: user.first_name,
    lastName: user.last_name,
    phone: user.phone,
  });
};

export const initAnalytics = () => {
  load();
  setBasePayload();
};

export const setUserTracking = (token: string) => {
  const tokenData: Token = jwt_decode(token);
  identify(tokenData.user as User);
  setBasePayload(tokenData);
};
