import { AppPages, EventProperties, track, TrackingActions, TrackingEventNames, TrackingLocations, TrackingObjects } from "./okTracker";

interface ButtonTackProperties extends Pick<EventProperties, 'view'> {
  label: string;
  location: string;
  [others: string]: string | number | boolean;
}

export const trackButtonTapped = (properties: ButtonTackProperties) => {
  track(TrackingEventNames.BUTTON_TAPPED, {
    onObject: TrackingObjects.button,
    action: TrackingActions.tapClick,
    ...properties
  });
}

export const trackHamburgerClick = (view: AppPages) => {
  trackButtonTapped({
    view,
    label: "Menu icon",
    location: TrackingLocations.headerBar,
  });
}

export const trackNavMenuItemClick = (view: AppPages, label: string, idx: number) => {
  trackButtonTapped({
    location: TrackingLocations.menu,
    view,
    label,
    position: idx,
  });
};

export const trackBackPress = (view: AppPages) => {
  trackButtonTapped({
    view,
    label: "Back icon",
    location: TrackingLocations.headerBar,
  });
};
