import create from "zustand";
import { combine } from "zustand/middleware";

const initialState = {
  online: false,
}

export const networkState = create(
  combine(initialState, (set, get) => ({
    setOnline: () => {
      set({online: true})
    },
    setOffline: () => {
      set({online: false})
    }
  }))
);