import React from "react";

const AddressSkeleton = () => {
  return (
    <div className="address-skeleton h-28 border mb-4 rounded-md animate-pulse bg-gray-200">
      
    </div>
  );
};

export default AddressSkeleton;
