import { Redirect } from 'react-router-dom';
import useNetwork from '../hooks/useNetwork';
type Props = {
    code?: string;
    message?: string;
};

const AppError = ({ code, message }: Props) => {
    const { online } = useNetwork();
    if (code && parseInt(code) === 410) {
        return <Redirect to="/" />;
    }
    return (
        <div className="flex flex-col items-center justify-center w-full h-full">
            {online ? (
                <>
                    <h1 className="font-bold text-gray-400 text-7xl">
                        {code || '404'}
                    </h1>

                    <p>{message || 'Not Found'}</p>
                </>
            ) : (
                <>
                    <h1 className="font-bold text-gray-400 text-7xl">
                        No Internet
                    </h1>
                    <p>You are offline. Check your connection</p>
                </>
            )}
        </div>
    );
};

export default AppError;
