import { History } from "history";
import React, { useEffect, useState } from "react";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import LazyComponentLoader from "../components/LazyComponentLoader";
import useNetwork from "../hooks/useNetwork";
import { storageService } from "../services/storage";
import { OkAppError } from "../utils/helper";
import { useAuthStore } from "../zustand/useAuthStore";

interface UnauthorizedComponentProps {
  error: OkAppError;
  history: History<unknown>;
}
interface ProtectedRouteProps {
  loadingComponent?: React.ReactNode;
  onUnauthorizedComponent?: (props: UnauthorizedComponentProps) => React.ReactNode;
  allowUnauthorized?: boolean;
  forceLoadingWhenAuthenticating?: boolean;
  // Its require to fix Known issues where useEffect doesn't execute on Route change to another protected route
  key: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps & RouteProps> = ({
  children,
  loadingComponent = <LazyComponentLoader />,
  onUnauthorizedComponent,
  allowUnauthorized,
  forceLoadingWhenAuthenticating,
  ...restProps
}) => {
  const { refreshToken, user } = useAuthStore(state => ({
    refreshToken: state.refreshToken,
    user: state.user,
  }));

  const { online } = useNetwork();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<OkAppError | null>(null);

  useEffect(() => {
    if (user) return setIsLoading(false);

    setError(null);
    forceLoadingWhenAuthenticating && setIsLoading(true);
    refreshToken()
      .then(res => {
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        setError(err);
      });
      return () => {
        setError(null);
        setIsLoading(true);
      }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshToken, online, forceLoadingWhenAuthenticating]);

  if (isLoading) {
    return <>{loadingComponent}</>;
  }

  if (error && online && !allowUnauthorized) {
    if(onUnauthorizedComponent) {
      return <>{onUnauthorizedComponent({error, history})}</>;
    } else {
      return <Redirect to="/" />
    }
  }

  const loggedInAsOffline = Boolean(storageService.getId());
  return (
    <Route {...restProps}>
      {Boolean(user || loggedInAsOffline || allowUnauthorized) && children}
    </Route>
  );
};

export default ProtectedRoute;
