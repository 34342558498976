import React, { ReactElement, useMemo } from "react";
import cn from "classnames";
import { networkState } from '../zustand/networkState';

interface Props {
  position: "bottom" | "top";
}
function OfflineIndicator({ position }: Props): ReactElement {
  const isOnline = networkState(state => state.online);
  const text = isOnline ? "Online" : "Offline";

  const snackBar = useMemo(() => {

    const topClassName = isOnline ? `slide-up-top` : `slide-down-top `;
    const bottomClassName = isOnline ? `slide-down-bottom` : `slide-up-bottom `;

    return (
      <div
        style={{
          zIndex: 1000,
          transition: "transform 0.5s ease", transitionDelay: "2s"
        }}
        className={cn(
          "absolute w-full overflow-hidden",
          position === "top" ? `top-0 ${topClassName}` : `bottom-0 ${bottomClassName}`,
        )}
      >
        <div
          className={cn(isOnline ? "bg-green-500" : "bg-gray-600")}
        >
          <p className="text-center text-white">{text}</p>
        </div>
      </div>
    );
  }, [isOnline, text, position]);

  return snackBar;
}

export default OfflineIndicator;
