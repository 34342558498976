import React, { useEffect } from "react";
import ReactQueryProvider from "../Context/ReactQueryProvider";

// Analytics
import {
  AppPages,
  initAnalytics,
  track,
} from "../services/analytics/okTracker";
import useNetwork from "../hooks/useNetwork";
import { networkState } from "../zustand/networkState"

import Routes from "./Routes";
import ThemeProvider from "../Context/ThemeProvider";

const App = () => {
  useEffect(() => {
    initAnalytics();
    track("App Loaded", {
      onObject: "app",
      action: "loaded",
      view: AppPages.root,
    });
  }, []);

  const { online } = useNetwork();

  useEffect(() => {
    networkState.setState({ online });
  }, [online]);

  return (
    <ReactQueryProvider>
      <ThemeProvider>
        <Routes />
      </ThemeProvider>
    </ReactQueryProvider>
  );
};

export default App;
